// src/Farms.js
import React from 'react';

const Farms = () => {
    return (
        <div>
            <h2>Farms Page</h2>
            {/* Add your Farms page content here */}
        </div>
    );
};

export default Farms;
