// src/Login.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId');
        if (userId) {
            setEmail(userId);
        }
    }, [location]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('/api/token/AuthByEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }), // Ensure the payload matches the API model
            });

            const data = await response.json();
            if (response.ok) {
                // Handle successful login
                console.log('Login successful:', data);

                // Store the access token in local storage or session storage
                localStorage.setItem('accessToken', data.AccessToken);

                // Redirect to the Farms page
                navigate('/farms');
            } else {
                // Handle login error
                console.error('Login error:', data);
                alert('Login failed: ' + (data.message || 'Wrong login or password'));

                // Redirect to the login page
                navigate('/login');
            }
        } catch (error) {
            console.error('Network error:', error);
            alert('Network error: ' + error.message);

            // Redirect to the login page
            navigate('/login');
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default Login;
